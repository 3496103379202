<template>
  <suc-text-field-component
    :title="$t('pages.profile.tabs.personal_data.dni_number')"
    v-bind="$attrs"
    v-on="$listeners"
  ></suc-text-field-component>
</template>

<script>
import { SucTextFieldComponent } from "@/components/form";

export default {
  components: {
    SucTextFieldComponent,
  },
};
</script>

<style></style>
